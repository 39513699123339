import { template as template_cf1048e82feb4dbc9474a900e93b7b6c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_cf1048e82feb4dbc9474a900e93b7b6c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
