import { template as template_fe9b0f4b918b480ab2ee61149b20f33b } from "@ember/template-compiler";
const FKLabel = template_fe9b0f4b918b480ab2ee61149b20f33b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
